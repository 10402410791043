/**
 *
 * @type {{CHOOSE_ONE: string, CHOOSE_MANY: string, getValueFromLabel: (function(string): (string)), getOptions: (function(): *[]), CHOOSE_N_ITEMS: string}}
 */
export const PROMPT_TYPE = function(){
  
  const CHOOSE_ONE_ID = 'chooseOne';
  const CHOOSE_MANY_ID = 'chooseMany';
  const CHOOSE_N_ITEMS_ID = 'chooseNItems';
  
  const CHOOSE_ONE_LABEL = 'Choose One';
  const CHOOSE_MANY_LABEL = 'Choose Many';
  const CHOOSE_N_ITEMS_LABEL = 'Choose N';
  
  const valueToLabel = {
    [CHOOSE_ONE_ID]: CHOOSE_ONE_LABEL,
    [CHOOSE_MANY_ID]: CHOOSE_MANY_LABEL,
    [CHOOSE_N_ITEMS_ID]: CHOOSE_N_ITEMS_LABEL
  };
  
  const PROMPT_TYPE_CHOICES = [
    {
      value: CHOOSE_ONE_ID,
      label: CHOOSE_ONE_LABEL
    },
    {
      value: CHOOSE_MANY_ID,
      label: CHOOSE_MANY_LABEL
    },
    {
      value: CHOOSE_N_ITEMS_ID,
      label: CHOOSE_N_ITEMS_LABEL
    }
  ];
  
  
  return {
    /**
     *
     * @returns {*[]}
     */
    getOptions: () => {
      return PROMPT_TYPE_CHOICES;
    },
  
    /**
     *
     * @param {string} value
     * @returns {string}
     */
    getLabelFromValue: (value) => {
      
      return valueToLabel[value];
    },
    
    CHOOSE_ONE: 'chooseOne',
    CHOOSE_MANY: 'chooseMany',
    CHOOSE_N_ITEMS: 'chooseNItems'
  };
}();
