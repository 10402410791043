require('es6-promise').polyfill();

import BluebirdPromise from 'bluebird';
import fetch from 'cross-fetch';
import { isString } from 'lodash';
import { JsonUtils } from '@foodbuzzer/foodbuzzer-shared/utils';


const getBody = (response) => {
  const contentType = response.headers.get('content-type');
  if(contentType && contentType.indexOf('application/json') !== -1) {
    //Its an RFC violation to return a payload on 204, so we dont want to parse
    // the empty response to throw an error, we just return a safe empty object
    if(response.status === 204) {
      return {};
    } else {
      return response.json();
    }
  } else {
    //Its an RFC violation to return a payload on 204, so we dont want to parse
    // the empty response to throw an error, we just return a safe empty object
    if(response.status === 204) {
      return '';
    } else {
      return response.text();
    }
  }
};

/**
 *
 * @param {Object} response
 * @param {*} response.status
 * @param {Object} body
 * @param {string} body.message
 * @constructor
 */
function FetchError(response, body) {
  this.name = 'FetchError';
  this.status = response.status;
  this.message = (body && body.message ? body.message : '');
  this.body = body;
}
FetchError.prototype = Error.prototype;

const checkResponseStatus = (response) => {
  if(response.status >= 200 && response.status < 300) {
    return response;
  } else {
    return getBody(response).then(body => {
      throw new FetchError(response, body);
    });
  }
};


let store;
let headerMapper = (state) => {
  const headers = {};
  if(state.session && state.session.sessionId) {
    headers['x-fbzr-session'] = state.session.sessionId;
  }
  if(state.auth && state.auth.idToken) {
    headers.Authorization = `Bearer ${state.auth.idToken}`;
  }
  return headers;
};

/**
 *
 * @param {Object} newStore
 */
const setReduxStore = (newStore) => {
  store = newStore;
};

/**
 *
 * @param {*} newMapper
 */
const setHeaderMapper = (newMapper) => {
  headerMapper = newMapper;
};


/**
 *
 * @param {string} url
 * @param {Object} options
 * @param {Object} options.body
 * @param {Object} options.header
 * @param {boolean} options.throwOnNonSuccess
 * @param {boolean} options.returnWholeResponse
 * @param {Object} options.data
 * @param {string} options.platformOS
 * @constructor
 */
const ApiFetch = (url, options = {}) => {
  let {body, headers, throwOnNonSuccess = true, returnWholeResponse = false, data, platformOS = 'ios'} = options;
  // Returning a bluebird promise allows the fetch function to use
  // bluebird functionality when fetching (such as "finally")
  return BluebirdPromise.resolve().then(() => {
    if(!isString(url)) {
      throw new Error("ApiFetch requires a 'url' property that is a string");
    }
    if(JsonUtils.isJson(body)) {
      body = JSON.stringify(body);
    }
    
    const mappedHeaders = store ? headerMapper(store.getState()) : null;
    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...mappedHeaders,
      ...headers
    };
    
    if(data) {
      //override contentType if data is supplied
      if(platformOS === 'ios') {
        delete headers['Content-Type'];
      } else if(platformOS === 'android') {
        headers['Content-Type'] = 'multipart/form-data';
      }
      
      body = data;
    }
    
    // Only allowing certain headers
    const cleanedHeaders = {
      'Authorization': headers['Authorization'],
      'x-fbzr-session': headers['x-fbzr-session'],
      'Accept': headers['Accept'],
      'Content-Type': headers['Content-Type']
    };
    
    if('Cache-Control' in headers) {
      cleanedHeaders['Cache-Control'] = headers['Cache-Control'];
    }
    
    options = {
      ...options,
      body,
      headers: cleanedHeaders
    };
    
    return fetch(url, options);
  }).then(response => {
    if(throwOnNonSuccess) {
      return checkResponseStatus(response);
    }
    return response;
  }).then(response => {
    if(returnWholeResponse) {
      return response;
    }
    return getBody(response);
  });
};

export { setReduxStore, setHeaderMapper, FetchError, ApiFetch };
