/**
 * @typedef {Object} ACTIONS
 * @property {string} LOGIN_REQUEST
 * @property {string} LOGIN_SUCCESS
 * @property {string} LOGOUT_SUCCESS
 * @property {string} APP_INITIALIZED
 * @property {string} AUTH_INITIALIZED
 *
 * @property {string} GET_FILTERED_RESTAURANTS
 * @property {string} GET_MANY_RESTAURANTS
 * @property {string} GET_RESTAURANT
 * @property {string} POST_RESTAURANT_START
 * @property {string} POST_RESTAURANT_SUCCESS
 * @property {string} PUT_RESTAURANT_START
 * @property {string} PUT_RESTAURANT_SUCCESS
 * @property {string} CLEAR_SEARCHED_RESTAURANTS
 *
 * @property {string} GET_FILTERED_MENU_ITEMS
 * @property {string} GET_MANY_MENU_ITEMS
 * @property {string} GET_MENU_ITEM
 * @property {string} POST_MENU_ITEM_START
 * @property {string} POST_MENU_ITEM_SUCCESS
 * @property {string} PUT_MENU_ITEM_START
 * @property {string} PUT_MENU_ITEM_SUCCESS
 * @property {string} CLEAR_SEARCHED_MENU_ITEMS
 *
 * @property {string} GET_FILTERED_ORDERS
 * @property {string} GET_MANY_ORDERS
 * @property {string} GET_ORDER
 * @property {string} POST_ORDER_START
 * @property {string} POST_ORDER_SUCCESS
 * @property {string} PUT_ORDER_START
 * @property {string} PUT_ORDER_SUCCESS
 * @property {string} CLEAR_SEARCHED_ORDERS
 */

/**
 * @type {ACTIONS}
 */
const ACTIONS = {};


const actionPrefixes = [
  'GET_FILTERED_{plural}',
  'GET_MANY_{plural}',
  'GET_{singular}',
  'POST_{singular}_START',
  'POST_{singular}_SUCCESS',
  'PUT_{singular}_START',
  'PUT_{singular}_SUCCESS',
  'CLEAR_SEARCHED_{plural}'
];
const generateActions = (singular, plural) => {

  actionPrefixes.forEach(item => {
    let actionToExport = item.replace(/\{singular\}/, singular.toLocaleUpperCase());
    actionToExport = actionToExport.replace(/\{plural\}/, plural.toLocaleUpperCase());

    ACTIONS[actionToExport] = actionToExport;
  });
};


ACTIONS.LOGIN_REQUEST = 'LOGIN_REQUEST';
ACTIONS.LOGIN_SUCCESS = 'LOGIN_SUCCESS';
ACTIONS.LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
ACTIONS.APP_INITIALIZED = 'APP_INITIALIZED';
ACTIONS.AUTH_INITIALIZED = 'AUTH_INITIALIZED';

//Profiles
ACTIONS.UPDATE_PROFILE = 'UPDATE_PROFILE';
ACTIONS.GET_PROFILE = 'GET_PROFILE';


//Restaurants
generateActions('restaurant', 'restaurants');
generateActions('menu_item', 'menu_items');
generateActions('order', 'orders');


export { ACTIONS };
