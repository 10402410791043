/**
 * Check if a given item is JSON
 * @param {*} item
 * @returns {boolean}
 */
export const isJson = (item) => {
  item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;
  
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  
  if (typeof item === "object" && item !== null) {
    return true;
  }
  
  return false;
};
