import { isEmpty, get, isNull } from 'lodash';
import { PROMPT_TYPE } from '../constants';

/**
 * Platform Fee (10%)
 * @type {number}
 */
export const PLATFORM_FEE = .1;

/**
 * Platform fee for Restaurant's Customer (3%)
 * @type {number}
 */
export const RESTAURANT_CUSTOMER_PLATFORM_FEE = .03;

/**
 * Consumer Fee in Cents (.50 USD)
 * @type {number}
 */
export const CONSUMER_FEE_IN_CENTS = 50;

/**
 * Delivery Fee in Cents (1.00 USD)
 * @type {number}
 */
export const DELIVERY_FEE_IN_CENTS = 100;


export const calculateCostForOrder = (order) => {
  return calculateCostForOrderManual(order, {}, null, false, {});
};

/**
 *
 * @param {{menuItems: Array}} order
 * @param {Object|{discountInCents: number, discountInPercentage: number, oncePerCustomer: boolean, minOrderInCents: number}} [coupon={}]
 * @param {number|string|null} taxRate
 * @param {boolean} isRestaurantCustomer
 * @param {{isDelivery?: boolean}} params
 * @returns {{
 *  grandTotal: number,
 *  orderTotal: number,
 *  orderTotalWithoutDiscounts: number,
 *  orderSalesTax: number,
 *  consumerOrderFee: number,
 *  restaurantAmount: number,
 *  qbDiscountAmount: number,
 *  restaurantDiscountAmount: number,
 *  discountAppliedBeforeSalesTax: boolean
 * }}
 * @private
 */
export const calculateCostForOrderManual = (order, coupon = {}, taxRate = null, isRestaurantCustomer = false, params = {}) => {
  const { isDelivery = false } = params;
  
  const orderTotalResult = calculateOrderTotalWithoutTaxSplit(order.menuItems);
  
  const minOrderSizeForCoupon = get(coupon, 'minOrderInCents', 1000);
  const hasCoupon = coupon.discountInCents || coupon.discountInPercentage;
  
  let taxableOrderTotalAfterDiscounts = orderTotalResult.taxablePrice;
  let orderTotalWithoutDiscounts = orderTotalResult.taxablePrice + orderTotalResult.nonTaxablePrice;
  let qbDiscountAmount = 0;
  //Tax nexus for restaurant will affect when discount is applied:
  // Each State is different and it should be computed based on Tax Nexus in restaurant
  // Texas = sales tax is calculated off the discount price
  // Other States = Sales tax is calculated off the total amount since discount
  //                comes from third party and restaurant is reimbursed
  let discountAppliedBeforeSalesTax = true;
  
  if(discountAppliedBeforeSalesTax) {
    if (hasCoupon) {
      if (coupon.discountInCents) {
        qbDiscountAmount = coupon.discountInCents;
        taxableOrderTotalAfterDiscounts = taxableOrderTotalAfterDiscounts - qbDiscountAmount;
      } else if (coupon.discountInPercentage) {
      
      }
    }
  }
  
  let orderTotal = taxableOrderTotalAfterDiscounts + orderTotalResult.nonTaxablePrice;
  if(hasCoupon && orderTotalWithoutDiscounts < minOrderSizeForCoupon) {
    qbDiscountAmount = 0;
    orderTotal = orderTotalWithoutDiscounts;
    taxableOrderTotalAfterDiscounts = orderTotalResult.taxablePrice;
  }
  
  let orderSalesTax = 0;
  if(isNull(taxRate)) {
    orderSalesTax = calculateOrderSalesTax(order.menuItems);
  } else {
    orderSalesTax = Math.round(calculateSalesTaxOnPrice(taxableOrderTotalAfterDiscounts, taxRate));
  }
  
  const consumerOrderFee = isDelivery ? DELIVERY_FEE_IN_CENTS : CONSUMER_FEE_IN_CENTS;
  
  let percentagePlatformFee = 0;
  if(!isRestaurantCustomer) {
    percentagePlatformFee = Math.round(PLATFORM_FEE * orderTotalWithoutDiscounts);
  } else {
    percentagePlatformFee = Math.round(RESTAURANT_CUSTOMER_PLATFORM_FEE * orderTotalWithoutDiscounts);
  }
  
  
  
  
  let grandTotal = orderTotal + orderSalesTax + consumerOrderFee;
  const restaurantAmount = orderSalesTax + (orderTotal - percentagePlatformFee);
  
  if(!discountAppliedBeforeSalesTax) {
    if (hasCoupon && orderTotal >= minOrderSizeForCoupon) {
      if (coupon.discountInCents) {
        qbDiscountAmount = coupon.discountInCents;
        grandTotal = grandTotal - qbDiscountAmount;
      } else if (coupon.discountInPercentage) {
      
      }
    }
  }
  
  return {
    grandTotal,
    orderTotal,
    orderTotalWithoutDiscounts,
    percentagePlatformFee,
    orderSalesTax,
    consumerOrderFee,
    restaurantAmount,
    qbDiscountAmount,
    restaurantDiscountAmount: 0,
    discountAppliedBeforeSalesTax
  };
};

/**
 *
 * @param {{menuItems: Array}} order
 * @param {Object|{discountInCents: number, discountInPercentage: number, oncePerCustomer: boolean, minOrderInCents: number}} [coupon={}]
 * @param {number|string|null} taxRate
 * @param {boolean} isRestaurantCustomer
 * @param {{isDelivery?: boolean}} params
 * @returns {{
 *  grandTotal: number,
 *  orderTotal: number,
 *  orderTotalWithoutDiscounts: number,
 *  orderSalesTax: number,
 *  consumerOrderFee: number,
 *  restaurantAmount: number,
 *  qbDiscountAmount: number,
 *  restaurantDiscountAmount: number,
 *  discountAppliedBeforeSalesTax: boolean
 * }}
 * @private
 */
export const calculateCostForOrderManualV2 = (order, coupon = {}, taxRate = null, isRestaurantCustomer = false, params = {}) => {
  const { isDelivery = false } = params;
  
  const orderTotalResult = calculateOrderTotalWithoutTaxSplit(order.menuItems);
  
  const minOrderSizeForCoupon = get(coupon, 'minOrderInCents', 1000);
  const hasCoupon = coupon.discountInCents || coupon.discountInPercentage;
  
  const consumerOrderFee = isDelivery ? DELIVERY_FEE_IN_CENTS : CONSUMER_FEE_IN_CENTS;
  
  let taxableOrderTotalAfterDiscounts = orderTotalResult.taxablePrice;
  let orderTotalWithoutDiscounts = orderTotalResult.taxablePrice + orderTotalResult.nonTaxablePrice + consumerOrderFee;
  let orderTotalWithoutDiscountsOrConsumerFee = orderTotalResult.taxablePrice + orderTotalResult.nonTaxablePrice;
  let qbDiscountAmount = 0;
  //Tax nexus for restaurant will affect when discount is applied:
  // Each State is different and it should be computed based on Tax Nexus in restaurant
  // Texas = sales tax is calculated off the discount price
  // Other States = Sales tax is calculated off the total amount since discount
  //                comes from third party and restaurant is reimbursed
  let discountAppliedBeforeSalesTax = true;
  
  if(discountAppliedBeforeSalesTax) {
    if (hasCoupon) {
      if (coupon.discountInCents) {
        qbDiscountAmount = coupon.discountInCents;
        taxableOrderTotalAfterDiscounts = taxableOrderTotalAfterDiscounts - qbDiscountAmount;
      } else if (coupon.discountInPercentage) {
      
      }
    }
  }
  
  let orderTotal = taxableOrderTotalAfterDiscounts + orderTotalResult.nonTaxablePrice + consumerOrderFee;
  if(hasCoupon && orderTotalWithoutDiscounts < minOrderSizeForCoupon) {
    qbDiscountAmount = 0;
    orderTotal = orderTotalWithoutDiscounts;
    taxableOrderTotalAfterDiscounts = orderTotalResult.taxablePrice;
  }
  
  let orderSalesTax = 0;
  let restaurantOrderSalesTax = 0;
  if(isNull(taxRate)) {
    orderSalesTax = calculateOrderSalesTax(order.menuItems);
    restaurantOrderSalesTax = orderSalesTax;
  } else {
    orderSalesTax = Math.round(calculateSalesTaxOnPrice(taxableOrderTotalAfterDiscounts, taxRate));
    restaurantOrderSalesTax = Math.round(calculateSalesTaxOnPrice(orderTotalWithoutDiscountsOrConsumerFee, taxRate));
  }
  
  let percentagePlatformFee = 0;
  if(!isRestaurantCustomer) {
    percentagePlatformFee = Math.round(PLATFORM_FEE * (orderTotalWithoutDiscountsOrConsumerFee));
  } else {
    percentagePlatformFee = Math.round(RESTAURANT_CUSTOMER_PLATFORM_FEE * orderTotalWithoutDiscountsOrConsumerFee);
  }
  
  
  //safe guards if discount cover the entire orderTotal
  orderTotal = Math.max(orderTotal, 0);
  orderSalesTax = Math.max(orderSalesTax, 0);
  
  
  let grandTotal = orderTotal + orderSalesTax;
  const restaurantAmount = restaurantOrderSalesTax + (orderTotalWithoutDiscountsOrConsumerFee - percentagePlatformFee);
  
  if(!discountAppliedBeforeSalesTax) {
    if (hasCoupon && orderTotal >= minOrderSizeForCoupon) {
      if (coupon.discountInCents) {
        qbDiscountAmount = coupon.discountInCents;
        grandTotal = grandTotal - qbDiscountAmount;
      } else if (coupon.discountInPercentage) {
      
      }
    }
  }
  
  return {
    grandTotal,
    orderTotal,
    orderTotalWithoutDiscounts,
    orderTotalWithoutDiscountsOrConsumerFee,
    percentagePlatformFee,
    orderSalesTax,
    restaurantOrderSalesTax,
    consumerOrderFee,
    restaurantAmount,
    qbDiscountAmount,
    restaurantDiscountAmount: 0,
    discountAppliedBeforeSalesTax
  };
};

/**
 *
 * @param {Array} orderMenuItems
 * @param {Object} orderMenuItems[]
 * @param {number} orderMenuItems[].quantity
 * @param {Object} orderMenuItems[].menuItem
 * @param {number} orderMenuItems[].menuItem.priceWithoutTax
 * @return {number}
 * @private
 */
export const calculateOrderTotalWithoutTax = (orderMenuItems) => {
  let orderWithoutTax = 0;
  (orderMenuItems || []).forEach(orderMenuItem => {
    
    orderWithoutTax += calculateCostWithoutTaxForOrderMenuItem(orderMenuItem);
  });
  
  return orderWithoutTax;
};

/**
 *
 * @param {Array} orderMenuItems
 * @param {Object} orderMenuItems[]
 * @param {number} orderMenuItems[].quantity
 * @param {Object} orderMenuItems[].menuItem
 * @param {number} orderMenuItems[].menuItem.priceWithoutTax
 * @return {{taxablePrice: number, nonTaxablePrice: number}}
 * @private
 */
export const calculateOrderTotalWithoutTaxSplit = (orderMenuItems) => {
  
  const totalSplitResult = {
    taxablePrice: 0,
    nonTaxablePrice: 0
  };
  (orderMenuItems || []).forEach(orderMenuItem => {
    
    if(orderMenuItem.menuItem.isTaxable) {
      totalSplitResult.taxablePrice += calculateCostWithoutTaxForOrderMenuItem(orderMenuItem);
    } else {
      totalSplitResult.nonTaxablePrice += calculateCostWithoutTaxForOrderMenuItem(orderMenuItem);
    }
  });
  
  return totalSplitResult;
};

export const calculateOrderSalesTax = (orderMenuItems) => {
  return calculateOrderSalesTaxManual(orderMenuItems);
};

/**
 *
 * @param {Array} orderMenuItems
 * @param taxRate
 * @param {Object} orderMenuItems[]
 * @param {number} orderMenuItems[].quantity
 * @param {Object} orderMenuItems[].menuItem
 * @param {boolean} orderMenuItems[].menuItem.isTaxable
 * @param {number} orderMenuItems[].menuItem.taxRate
 * @param {number} orderMenuItems[].menuItem.priceWithoutTax
 * @returns {number}
 * @private
 */
export const calculateOrderSalesTaxManual = (orderMenuItems, taxRate = null) => {
  let orderSalesTax = 0;
  (orderMenuItems || []).forEach(orderMenuItem => {
    
    if(orderMenuItem.menuItem.isTaxable) {
      
      const menuItem = orderMenuItem.menuItem;
      
      let computedTaxRate = menuItem.taxRate;
      if(!isNull(taxRate)) {
        computedTaxRate = taxRate;
      }
      
      const calculatedOrderMenuItemPrice = calculateCostWithoutTaxForOrderMenuItem(orderMenuItem);
      
      orderSalesTax += calculateSalesTaxOnPrice(calculatedOrderMenuItemPrice, computedTaxRate);
    }
  });
  
  return Math.round(orderSalesTax);
};

export const calculateSalesTaxOnPrice = (totalInCents, taxRate) => {

  const scrubbedTaxRate = String(taxRate).replace(/[^\d.-]/g, '');
  const scrubbedTaxRateNumber = Number(scrubbedTaxRate);
  
  const taxRateInDecimal = (scrubbedTaxRateNumber || 0) / 100;
  
  const salesTax = totalInCents * taxRateInDecimal;
  
  return salesTax;
};

/**
 *
 * @param {Object} orderMenuItem
 * @param {number} orderMenuItem.quantity
 * @param {Object} orderMenuItem.menuItem
 * @param {boolean} orderMenuItem.menuItem.isTaxable
 * @param {number} orderMenuItem.menuItem.taxRate
 * @param {number} orderMenuItem.menuItem.priceWithoutTax
 * @param {Object} orderMenuItem.menuItem.choices
 * @param {Object} orderMenuItem.selectedChoices
 */
export const calculateCostWithoutTaxForOrderMenuItem = (orderMenuItem) => {
  
  if(isNaN(orderMenuItem.quantity) || orderMenuItem.quantity <= 0) {
    return 0;
  }
  
  const menuItem = orderMenuItem.menuItem;
  
  //add together option costs
  let additionalChoicePrice = 0;
  const choiceIds = Object.keys(orderMenuItem.selectedChoices || {});
  if(choiceIds.length > 0) {
    choiceIds.forEach(choiceId => {
      const selectedOption = orderMenuItem.selectedChoices[choiceId];
      const menuItemChoice = menuItem.choices[choiceId];
      const optionPrice = _getSelectedChoicePrice(menuItemChoice, selectedOption);
    
      additionalChoicePrice += optionPrice;
    });
  }
  
  
  return orderMenuItem.quantity * ( menuItem.priceWithoutTax + additionalChoicePrice );
};

/**
 *
 * @param menuItemChoice
 * @param selectedOption
 * @returns {number}
 * @private
 */
export const _getSelectedChoicePrice = (menuItemChoice, selectedOption) => {
  let optionPrice = 0;
  
  if(isEmpty(menuItemChoice) || isEmpty(selectedOption) || isEmpty(menuItemChoice.options)) {
    return optionPrice;
  }
  
  if(menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_ONE ||
     menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_MANY ||
     menuItemChoice.promptType === PROMPT_TYPE.CHOOSE_N_ITEMS) {
    const choiceOptionLength = menuItemChoice.options.length;
    for (let index = 0; index < choiceOptionLength; index++) {
      const option = menuItemChoice.options[index];
      if (option.id in selectedOption) {
        optionPrice += option.price;
      }
    }
  }
  
  return optionPrice;
};
