/**
 *
 * @type {{Mountain_Time_Denver, Eastern_Time, Mountain_Time_Phoenix, Hawaii_Aleutian_Time_Adak, Hawaii_Aleutian_Time_Honolulu, Alaska_Time, Central_Time, Pacific_Time}&{getLabelFromValue: (function(*): *), getOptions: (function(): *[])}}
 */
export const TIMEZONES = /**
 * @return {{Mountain_Time_Denver, Eastern_Time, Mountain_Time_Phoenix, Hawaii_Aleutian_Time_Adak, Hawaii_Aleutian_Time_Honolulu, Alaska_Time, Central_Time, Pacific_Time} & {getLabelFromValue: (function(*): *), getOptions: (function(): *[])}}
 */
  function() {
  
  
  const TIMEZONE_OPTIONS = [
    {
      label: 'Eastern Time',
      value: 'America/New_York'
    },
    {
      label: 'Central Time',
      value: 'America/Chicago'
    },
    {
      label: 'Mountain Time (Phoenix)',
      value: 'America/Phoenix'
    },
    {
      label: 'Mountain Time (Denver)',
      value: 'America/Denver'
    },
    {
      label: 'Pacific Time',
      value: 'America/Los_Angeles'
    },
    {
      label: 'Hawaii–Aleutian Time (Honolulu)',
      value: 'Pacific/Honolulu'
    },
    {
      label: 'Hawaii–Aleutian Time (Adak)',
      value: 'America/Adak'
    },
    {
      label: 'Alaska Time',
      value: 'America/Anchorage'
    }
  ];
  
  const keyToValue = {
    'Eastern_Time': 'America/New_York',
    'Central_Time': 'America/Chicago',
    'Mountain_Time_Phoenix': 'America/Phoenix',
    'Mountain_Time_Denver': 'America/Denver',
    'Pacific_Time': 'America/Los_Angeles',
    'Hawaii_Aleutian_Time_Honolulu': 'Pacific/Honolulu',
    'Hawaii_Aleutian_Time_Adak': 'America/Adak',
    'Alaska_Time': 'America/Anchorage'
  };
  
  const valueToLabel = {
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Phoenix': 'Mountain Time (Phoenix)',
    'America/Denver': 'Mountain Time (Denver)',
    'America/Los_Angeles': 'Pacific Time',
    'Pacific/Honolulu': 'Hawaii-Aleutian Time (Honolulu)',
    'America/Adak': 'Hawaii-Aleutian Time (Adak)',
    'America/Anchorage': 'Alaska Time'
  };
  
  
  return {
    /**
     *
     * @returns {{label: string, value: string}[]}
     */
    getOptions: () => {
      return TIMEZONE_OPTIONS;
    },
  
    /**
     *
     * @param value
     * @returns {string}
     */
    getLabelFromValue: (value) => {
      
      return valueToLabel[value];
    },
    ...keyToValue

  };
}();
