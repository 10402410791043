

export const GROUP_ORDER_STATUS = function() {
  
  const statuses = {
    OPEN: 'open',
    CONFIRMING: 'confirming',
    CONFIRMED: 'confirmed',
    PICKED_UP: 'pickedup',
    COMPLETE: 'complete'
  };
  
  return {
    ...statuses,
  
    /**
     *
     * @param {string} currentStatus
     * @param {string} newStatus
     * @returns {boolean}
     */
    canTransition: (currentStatus, newStatus) => {
      
      if(currentStatus === newStatus) {
        return true;
      }
      
      if(currentStatus === statuses.OPEN) {
        if(newStatus === statuses.CONFIRMING || newStatus === statuses.CONFIRMED) {
          return true;
        } else {
          return false;
        }
      } else if(currentStatus === statuses.CONFIRMING) {
        if(newStatus === statuses.CONFIRMED) {
          return true;
        } else {
          return false;
        }
      } else if(currentStatus === statuses.CONFIRMED) {
        if(newStatus === statuses.PICKED_UP) {
          return true;
        } else {
          return false;
        }
      } else if(currentStatus === statuses.PICKED_UP) {
        if(newStatus === statuses.COMPLETE) {
          return true;
        } else {
          return false;
        }
      }
      
      return false;
    },
  
    /**
     *
     * @param {String} statusString
     * @returns {boolean}
     */
    isValid: (statusString) => {
      if(statusString === statuses.OPEN ||
        statusString === statuses.CONFIRMING ||
        statusString === statuses.CONFIRMED ||
        statusString === statuses.PICKED_UP ||
        statusString === statuses.COMPLETE) {
        return true;
      } else {
        return false;
      }
    }
  };
}();
