/**
 *
 * @type {{NEW: string, ACCEPTED: string, ORDER_READY: string, COMPLETE: string, REJECTED: string, CANCELLED: string, canTransition: function}}
 */
export const ORDER_STATUS = function(){
  
  
  
  const statuses = {
    NEW: 'new',
    ACCEPTED: 'accepted',
    ORDER_READY: 'orderReady',
    COMPLETE: 'complete',
    REJECTED: 'rejected',
    CANCELLED: 'cancelled'
  };
  
  return {
    ...statuses,
    
    /**
     *
     * @param {String} currentStatus
     * @param {String} newStatus
     * @returns {boolean}
     */
    canTransition: (currentStatus, newStatus) => {
      
      if (currentStatus === newStatus) {
        return true;
      }
      
      if (currentStatus === statuses.NEW) {
        if (newStatus === statuses.ACCEPTED ||
          newStatus === statuses.REJECTED ||
          newStatus === statuses.CANCELLED) {
          return true;
        } else {
          return false;
        }
      } else if (currentStatus === statuses.ACCEPTED) {
        if (newStatus === statuses.ORDER_READY ||
          newStatus === statuses.CANCELLED ||
          newStatus === statuses.REJECTED) {
          return true;
        } else {
          return false;
        }
      } else if (currentStatus === statuses.ORDER_READY) {
        if (newStatus === statuses.COMPLETE) {
          return true;
        } else {
          return false;
        }
      }
      
      return false;
    },
    
    /**
     *
     * @param {String} statusString
     * @returns {boolean}
     */
    isValid: (statusString) => {
      if (statusString === statuses.NEW ||
        statusString === statuses.ACCEPTED ||
        statusString === statuses.REJECTED ||
        statusString === statuses.CANCELLED ||
        statusString === statuses.ORDER_READY ||
        statusString === statuses.COMPLETE) {
        return true;
      } else {
        return false;
      }
    }
    
  };
}();
