/**
 *
 * @type {{Takeout_Only, Delivery_Only, Delivery_And_Takeout}&{getLabelFromValue: (function(*): string), getOptions: (function(): *[])}}
 */
export const AVAILABILITY = /**
 * @return {{Takeout_Only, Delivery_Only, Delivery_And_Takeout} & {getLabelFromValue: (function(string): string), getOptions: (function(): *[])}}
 */
  function() {
  
  const TAKEOUT_ONLY_ID = 'takeoutonly';
  const DELIVERY_ONLY_ID = 'deliveryonly';
  const DELIVERY_AND_TAKEOUT_ID = 'deliveryandtakeout';
  
  const AVAILABILITY_CHOICES = [
    {
      id: TAKEOUT_ONLY_ID,
      name: 'Takeout only',
      description: 'Available during takeout only.',
    },
    {
      id: DELIVERY_ONLY_ID,
      name: 'Delivery only',
      description: 'Available during delivery only.',
    },
    {
      id: DELIVERY_AND_TAKEOUT_ID,
      name: 'Delivery and Takeout',
      description: 'Available for all delivery or takeout orders.',
    },
  ];
  
  const keyToValue = {
    'Takeout_Only': TAKEOUT_ONLY_ID,
    'Delivery_Only': DELIVERY_ONLY_ID,
    'Delivery_And_Takeout': DELIVERY_AND_TAKEOUT_ID
  };
  
  const valueToLabel = {
    [TAKEOUT_ONLY_ID]: 'Takeout only',
    [DELIVERY_ONLY_ID]: 'Delivery only',
    [DELIVERY_AND_TAKEOUT_ID]: 'Delivery and Takeout'
  };
  
  return {
  
    /**
     *
     * @returns {*[]}
     */
    getOptions: () => {
      return AVAILABILITY_CHOICES;
    },
  
    /**
     *
     * @param {string} value
     * @returns {string}
     */
    getLabelFromValue: (value) => {
      
      return valueToLabel[value];
    },
    ...keyToValue
  };
}();
