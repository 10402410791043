import { has, get, isEmpty } from 'lodash';
import URL from 'url-parse';


/**
 * Returns true if the hostname belongs to cloudinary
 * @param {string} hostname
 * @return {boolean}
 */
export const isCloudinaryHostedImage = (hostname) => {
  return ((hostname || '').toLocaleUpperCase() === 'res.cloudinary.com'.toLocaleUpperCase());
};


/**
 *
 * @param {string} imageSrc
 * @param {Object} options
 * @param {number} options.w Expected width of the image
 * @param {number} options.h Expected height of the image
 * @param {string} [options.repeatOption='no-repeat']
 * @param {string} [options.sizeOption='cover']
 * @param {string} [options.positionOption='']
 * @return {{backgroundImage: string, backgroundRepeat: *, backgroundSize: *}}
 */
export const buildBackgroundImageStyleObject = (imageSrc, options = {}) => {
  
  const parser = new URL(imageSrc);
  
  let optimizedSource = imageSrc;
  if(isCloudinaryHostedImage(parser.hostname)) {
    //replaces all http with https images
    optimizedSource = attachCloudinaryOptionsToImageSource(optimizedSource, options);
  }
  
  const repeatOption = get(options, 'repeatOption', 'no-repeat');
  const sizeOption = get(options, 'sizeOption', 'cover');
  const positionOption = get(options, 'positionOption', '');
  
  /**
   * -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   */
  if(!optimizedSource) {
    return;
  }
  
  const result = {
    backgroundImage: `url(${optimizedSource})`,
    backgroundRepeat: repeatOption,
    backgroundSize: sizeOption
  };
  
  if(!isEmpty(positionOption)) {
    result.backgroundPosition = positionOption;
  }
  
  return result;
};


/**
 *
 * @param {string} imageSrc
 * @param {Object} options
 * @param {number} [options.w] Expected width of the image
 * @param {number} [options.h] Expected height of the image
 * @param {string} [options.crop]
 * @param {string} [options.q]
 * @param {string} [options.f]
 * @return {string}
 */
export const attachCloudinaryOptionsToImageSource = (imageSrc, options = {}) => {
  //replaces all http with https images
  let optimizedSource = imageSrc.replace('http:', 'https:');
  
  const replacementString = [];
  
  if(get(options, 'w', '')) {
    replacementString.push(`w_${options.w}`);
  }
  if(get(options, 'h', '')) {
    replacementString.push(`h_${options.h}`);
  }
  if(get(options, 'crop', '')) {
    replacementString.push(`c_${options.crop}`);
  }
  
  if(get(options, 'q', '')) {
    replacementString.push(`q_${options.q}`);
  } else {
    replacementString.push('q_auto');
  }
  
  let dprSetting = get(options, 'dpr', '3.0');
  if(dprSetting !== '1.0' && dprSetting !== '2.0' && dprSetting !== '3.0') {
    dprSetting = '3.0';
  }
  if(dprSetting) {
    replacementString.push(`dpr_${dprSetting}`);
  }
  
  if(get(options, 'color', '')) {
    replacementString.push(`e_colorize:66,co_rgb:${options.color}`);
  }
  
  if(get(options, 'f', '')) {
    replacementString.push(`f_${options.f}`);
  } else {
    replacementString.push('f_auto');
  }
  
  if(replacementString.length > 0) {
    optimizedSource = optimizedSource.replace(/\/v\d+\//i, `/${replacementString.join(',')}/`);
  }
  
  return optimizedSource;
};
