/**
 *
 * @type {{CREDIT_CARD: string, APPLE_PAY: string, ANDROID_PAY: string, isValid: function}}
 */
export const ORDER_PAYMENT_METHOD = function(){
  
  
  
  const methods = {
    CREDIT_CARD: 'credit_card',
    APPLE_PAY: 'apple_pay',
    ANDROID_PAY: 'android_pay'
  };
  
  return {
    ...methods,
    
    /**
     *
     * @param {String} methodString
     * @returns {boolean}
     */
    isValid: (methodString) => {
      if (methodString === methods.CREDIT_CARD ||
        methodString === methods.APPLE_PAY ||
        methodString === methods.ANDROID_PAY) {
        return true;
      } else {
        return false;
      }
    }
    
  };
}();
