import {keysIn, isEmpty} from 'lodash';

/**
 * Replaces multiple strings with multiple other strings
 * @param {string|null} str
 * @param {Object} mapObj
 * @param {Object} [options={}]
 * @param {Object} [options.caseSensitive=false]
 * @return {string}
 */
const replaceAll = (str, mapObj, options = {}) => {
  if(isEmpty(str)) {
    return '';
  }

  const DEFAULT_OPTIONS = {
    caseSensitive: false
  };

  const formattedOptions = {
    ...DEFAULT_OPTIONS,
    ...options
  };

  const keysInObject = keysIn(mapObj);
  if(keysInObject.length <= 0) {
    return str;
  }

  //escape keys
  const escapedKeysInObject = keysInObject.map(regexStr => {
    return escapeRegExp(regexStr);
  });

  //build regexOptions
  const regexOptions = `g${formattedOptions.caseSensitive ? '' : 'i'}`;

  const re = new RegExp(escapedKeysInObject.join('|'), regexOptions);

  return str.replace(re, (matched) => {
    if(formattedOptions.caseSensitive) {
      return mapObj[matched];
    } else {
      return mapObj[matched.toLowerCase()];
    }
  });
};

/**
 *
 * @param {string} url
 * @param {string} key
 * @param {string|number} value
 * @return {string}
 */
const addUrlParameter = (url, key, value) => {
  if(isEmpty(url)) {
    return '';
  }

  // remove the hash part before operating on the uri
  const hashIndex = url.indexOf('#');
  const hash = hashIndex === -1 ? ''  : url.substr(hashIndex);
  url = hashIndex === -1 ? url : url.substr(0, hashIndex);

  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = url.indexOf('?') !== -1 ? "&" : "?";

  if (url.match(re)) {
    url = url.replace(re, `$1${key}=${value}$2`);
  } else {
    url = `${url}${separator}${key}=${value}`;
  }
  return `${url}${hash}`;  // finally append the hash as well
};

/**
 *
 * @param {string} url Url to check and remove querystring parameter
 * @param {string} parameter Named query string parameter that will be removed if it exists
 * @return {string}
 */
const removeUrlParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  const urlParts= url.split('?');
  if (urlParts.length < 2) {
    return url;
  }

  const prefix = `${encodeURIComponent(parameter)}=`;
  let pars = urlParts[1].split(/[&;]/g);

  //reverse iteration as may be destructive
  for (let i = pars.length; i-- > 0;) {
    //idiom for string.startsWith
    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
      pars.splice(i, 1);
    }
  }

  url = urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  return url;
};

/**
 * Escapes a string to prepare it to be used in a regex
 * @param {string} str
 * @return {string}
 */
const escapeRegExp = (str) => {
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 *
 * @param {string} string
 * @return {string}
 */
const htmlDecode = (string) => {

  const replacementValues = {
    '&amp;': '&'
  };

  return replaceAll(string, replacementValues);
};


export { replaceAll, addUrlParameter, removeUrlParameter, htmlDecode, escapeRegExp };
