/**
 *
 * @param type
 * @param value
 * @param exp
 * @returns {*}
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (value === null || isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // If the value is negative...
  if (value < 0) {
    return -decimalAdjust(type, -value, exp);
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

/**
 *
 * @param {number} value
 * @param exp
 * @returns {*}
 */
export const round10 = (value, exp) => {
  return decimalAdjust('round', value, exp);
};

/**
 *
 * @param {number} value
 * @param exp
 * @returns {*}
 */
export const floor10 = (value, exp) => {
  return decimalAdjust('round', value, exp);
};

/**
 *
 * @param {number} value
 * @param exp
 * @returns {*}
 */
export const ceil10 = (value, exp) => {
  return decimalAdjust('ceil', value, exp);
};

/**
 *
 * @param {number} amount
 * @param {{removeExtraDecimalPlaces: boolean}} [options={}]
 * @returns {string}
 */
export const convertCentsToUSD = (amount = 0, options = {}) => {
  const { removeExtraDecimalPlaces = false } = options;
  
  const convertedAmount = (amount / 100);
  
  let decimalCount = 2;
  if(removeExtraDecimalPlaces && amount % 100 === 0) {
    decimalCount = 0;
  }
  
  return formatMoney(convertedAmount, '$', decimalCount);
};

/**
 *
 * @param {number} amount
 * @param {string} [prefixSymbol="$"]
 * @param {number} [decimalCount=2]
 * @param {string} [decimal="."]
 * @param {string} [thousands=","]
 * @returns {string}
 */
export const formatMoney = (amount, prefixSymbol = '$', decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
    const negativeSign = amount < 0 ? '-' : '';
    
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    
    return negativeSign + prefixSymbol + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * @param {number} numerator
 * @param {number} denominator
 * @returns {number}
 */
export const calculatePercentage = (numerator, denominator) => {
  if (!denominator || isNaN(denominator)) {
    return 0;
  }

  if (!numerator || isNaN(denominator)) {
    return 0;
  }

  if (typeof numerator !== 'number' || typeof denominator !== 'number') {
    return 0;
  }

  return Math.floor((numerator / denominator) * 100);
};
